export const missionsTypes = {
  SIGN_IN: "sign-in",
  TWITTER_FOLLOW: "Follow us",
  TELEGRAM__JOIN: "Join community",
  TELEGRAM_JOIN_NO_BOT: "Join TG no bot",
  DAILY: "daily",
  TELEGRAM_BOOST: "telegram-boost",
  REFER_PREMIUM: "refer-premium",
  PLAY_APP: "Play app",
  COLLABORATION: "collaboration",
  SHARE_STORIES: "TG story",
  ACADEMY: "academy",
  ADVERTISING: "advertising",
  OKX: "OKX",
  OKX_COMMUNITY: "OKX_community",
  OKX_MINT: "mint-bird-nft",
  OKX_PLAY_APP: "okx_play_app",
  FACE_SCAN: "face-scan",
};

export const missionsOptions = [
  {
    icon: "/images/icons/daily.png",
    title: "Invite a friend",
    description: "Daily",
    seed: 0.05,
    type: missionsTypes.DAILY,
  },
  {
    icon: "/images/icons/twitter.png",
    title: "Follow on Twitter",
    description: "Twitter",
    seed: 0.02,
    type: missionsTypes.TWITTER_FOLLOW,
  },
  {
    icon: "/images/icons/telegram.png",
    title: "Telegram",
    description: "Telegram",
    seed: 0.02,
    type: missionsTypes.TELEGRAM__JOIN,
  },
];

export const socials: { [key: string]: string } = {
  [missionsTypes.TWITTER_FOLLOW]: "Twitter",
  [missionsTypes.TELEGRAM__JOIN]: "Telegram",
  Discord: "Discord",
};

export const dailyBonusValue = [
  10000000, 20000000, 70000000, 30000000, 20000000, 50000000, 100000000,
];

export enum FacescanVerifyStatus {
  PROCESSING = -1,
  FAIL = 0,
  REGISTER_SUCCESS = 1,
  VERIFY_SUCCESS = 2,
}
