export const formatNumberFloatFix = (value: number | string, fix: number) => {
  if (!value) return "0";
  return Number(value).toFixed(fix);
  // .replace(/[.,]0$|[.,]00$|[.,]000$|[.,]0000$|0$|00$|000$|0000$/, "");
};

export const formatDecimals = (value: number | string) => {
  const result = Number(value) / Math.pow(10, 9);
  return result;
};

export const getNumberFormatUs = (
  number: number | string | undefined | null,
  fix?: number,
) => {
  if (typeof number !== "number" && typeof number !== "string") return "0";
  const numberTypeNumb = typeof number === "string" ? Number(number) : number;
  const numberFormatFloat = formatNumberFloatFix(numberTypeNumb, fix ? fix : 2);
  return Number(numberFormatFloat)?.toLocaleString("en-US", {
    maximumFractionDigits: fix ? fix : 2,
  });
};

export const convertNumber = (number: number) => {
  const thresholds = [100, 500, 1000, 5000, 10000, 100000];
  for (let i = thresholds.length - 1; i >= 0; i--) {
    if (number >= thresholds[i]) {
      return thresholds[i] + "+";
    }
  }
  return number.toString();
};

export const formatNumberUS = (value: number | string) => {
  return typeof value === "number"
    ? value.toLocaleString("en-US")
    : (+value).toLocaleString("en-US");
};

export function formatCompactNumber(value: number | string) {
  const valueToNumber = Number(value);
  const billionsAmount = valueToNumber / 1000000000;
  if (billionsAmount >= 1) return formatNumberUS(billionsAmount) + "B";
  const millionsAmount = valueToNumber / 1000000;
  if (millionsAmount >= 1) return formatNumberUS(millionsAmount) + "M";
  const thousandsAmount = valueToNumber / 1000;
  if (thousandsAmount >= 1) return formatNumberUS(thousandsAmount) + "K";
  return formatNumberUS(formatNumberFloatFix(valueToNumber, 2));
}

export const convertUuidToInt = (id: string) => {
  // remove the dashes from the given uuid and convert to a hexadecimal BigNumber object
  const indexInt = BigInt(`0x${id.replace(/-/g, "")}`); // return the decimal representation of the BigNumber object as a string
  return indexInt;
};

export const convertIntToUuid = (num: number | string): string => {
  try {
    // Validate input
    if (typeof num !== "number" && typeof num !== "string") {
      throw new Error(
        "Input must be a number or string representation of a number.",
      );
    }

    // Convert to BigInt for safer handling of large numbers
    const bn = BigInt(num);

    // Convert to hexadecimal string
    let id = bn.toString(16);

    // Ensure the string has enough characters for UUID format (32 characters)
    id = id.padStart(32, "0");

    // Return the string formatted as a UUID (8-4-4-4-12)
    return `${id.slice(0, 8)}-${id.slice(8, 12)}-${id.slice(12, 16)}-${id.slice(
      16,
      20,
    )}-${id.slice(20)}`;
  } catch (error: any) {
    throw new Error(`Error converting number to UUID: ${error.message}`);
  }
};
