export const navPaths = {
  HOME: "/",
  EARN: "/earn",
  MISSIONS: "/missions",
  BOOTS: "/boots",
  FRIENDS: "/friends",
  GAMES: "/games",
  REGISTER: "/register",
  LEADERBOARD: "/leaderboard",
  MAINTENANCE: "/maintenance",
  RELOAD: "/reload",
  VOTING: "/voting",
  TUT: "/tutorial",
  DAPP: "/dapp",
  MYSTERY_BOX: "/mystery-box",
  UPGRADE_BOX: "/upgrade-box",
  OPEN_BOX: "/open-box",
  INVENTORY: "/inventory",
  MARKET: "/market",
  MARKET_ITEM: "/market-item",
  INVENTORY_DETAIL: "/inventory-detail",
  PURCHASE: "/purchase",
  OPEN_PURCHASE_BOX: "/open-purchase-box",
  OPEN_PURCHASED_MULTI_BOX: "/open-purchase-multi-box",
  BIRD: "/bird",
  FEED_BIRD: "/feed-bird",
  BIRD_DETAIL: "/bird-detail",
  PROFILE: "/profile",
  SPIN_EGG: "/spin-egg",
  STREAK: "/streak",
  GUILD_WELCOME: "/guild-welcome",
  GUILD_LIST: "/guild-list",
  GUILD_DETAILS: "/guild-details",
  GUILD_MESSAGE: "/guild-message",
  BIRD_CAGE: "/bird-cage",
  SEED_MEDAL: "/seed-medal",
  GAME_LIST: "/game-list",
  AIR_DROP: "/airdrop",
  BATTLE_HOME: "/battle-home",
  BATTLE_ONLINE: "/battle-online",
  FIND_MATCH: "/find-match",
  DEPLOY_BIRD: "/deploy-bird",
  END_GAME: "/end-game",
  PICK_TURN: "/pick-turn",
  ATTACK_SCREEN: "/attack-screen",
  INACTIVE: "/inactive",
  BANNED: "/banned",
};

export interface NavBarItem {
  icon?: string;
  iconInactive?: string;
  name: string;
  path: string;
  fatherName?: string;
  items?: NavBarItem[];
  halloween_icon?: string;
  halloween_iconInactive?: string;
  darkIcon?: string;
}

export const navbarItems: NavBarItem[] = [
  {
    // icon: "/images/navbar/missions.png",
    halloween_icon: "/images/navbar-v2/halloween_home_active.png",
    halloween_iconInactive: "/images/navbar-v2/halloween_home_inactive.png",
    icon: "/images/navbar-v2/home-active.png",
    iconInactive: "/images/navbar-v2/home-unactive.png",
    name: "Home",
    path: navPaths.HOME,
    darkIcon: "/images/navbar-v2/dark_home_active.png",
  },
  {
    // icon: "/images/navbar/boots.png",
    halloween_icon: "/images/navbar-v2/halloween_earn_active.png",
    halloween_iconInactive: "/images/navbar-v2/halloween_earn_inactive.png",
    icon: "/images/navbar-v2/earn-active.png",
    iconInactive: "/images/navbar-v2/earn-unactive.png",
    name: "Earn",
    path: navPaths.MISSIONS,
    darkIcon: "/images/navbar-v2/dark_earn_active.png",
  },
  // {
  //   // icon: "/images/navbar/boots.png",
  //   icon: "/images/navbar-v2/game.png",
  //   iconInactive: "/images/navbar-v2/game-inactive.png",
  //   name: "Game",
  //path: navPaths.GAME_LIST,
  //darkIcon:""/images/navbar-v2/home-active.png"/
  // },
  {
    // icon: "/images/navbar/boots.png",
    halloween_icon: "/images/navbar-v2/halloween_boost_active.png",
    halloween_iconInactive: "/images/navbar-v2/halloween_boost_inactive.png",
    icon: "/images/navbar-v2/boost-active.png",
    iconInactive: "/images/navbar-v2/boost-unactive.png",
    name: "Boost",
    path: navPaths.BOOTS,
    darkIcon: "/images/navbar-v2/dark_boost_active.png",
  },
  {
    // icon: "/images/navbar/friends.png",
    halloween_icon: "/images/navbar-v2/halloween_friends_active.png",
    halloween_iconInactive: "/images/navbar-v2/halloween_friends_inactive.png",
    icon: "/images/navbar-v2/friends-active.png",
    iconInactive: "/images/navbar-v2/friends-unactive.png",
    name: "Friends",
    path: navPaths.FRIENDS,
    darkIcon: "/images/navbar-v2/dark_friend_active.png",
  },
  {
    // icon: "/images/navbar/friends.png",
    halloween_icon: "/images/navbar-v2/airdrop-active.png",
    halloween_iconInactive: "/images/navbar-v2/airdrop-unactive.png",
    icon: "/images/navbar-v2/airdrop-active.png",
    iconInactive: "/images/navbar-v2/airdrop-unactive.png",
    name: "Airdrop",
    path: navPaths.AIR_DROP,
    darkIcon: "/images/navbar-v2/airdrop-active.png",
  },
  // {
  //   icon: "/images/market/marketplace_ic.png",
  //   name: "Market",
  //   path: navPaths.MARKET,
  // },
  // {
  //   icon: "/images/inventory.png",
  //   name: "Inventory",
  //   path: navPaths.INVENTORY,
  // },
];
