/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useMemo } from "react";

import { Outlet, useLocation } from "react-router-dom";
import Loading from "../common/Loading";
import clsx from "clsx";
import Stars from "../common/Stars";
import { useChangeMode } from "../../store/modeStore";
import NavBar from "../common/NavBar";
// import { useMissions } from "../../modules/Missions/Hooks/useGetMissions";
// import { missionsTypes } from "../../constants/missions.constants";
// import { useMissionStore } from "../../store/missionStore";
import { isMobile } from "react-device-detect";
import { navPaths } from "../../constants/navbar.constants";
import { useMissions } from "../../modules/Missions/Hooks/useGetMissions";
import { useMissionStore } from "../../store/missionStore";
import { missionsTypes } from "../../constants/missions.constants";
import { useDailyMissions } from "../../modules/Missions/Hooks/useGetDaily";
import { checkSameDayV2 } from "../../utils/helper";
import { useChangeTheme } from "../../store/themeStore";
import { themeValue } from "../../modules/Profile/constants";
import { useBirdStore } from "../../store/birdStore";

const HomeLayout = () => {
  const tele = window.Telegram.WebApp;
  const theme = useChangeMode((state: any) => state.mode);
  const mode = localStorage.getItem("mode");
  const setHasMissions = useMissionStore((state: any) => state.setHasMission);
  const setHasBirdMission = useBirdStore((state: any) => state.setBirdMission);
  const location = useLocation();
  const currentTheme = useChangeTheme((state: any) => state.theme);

  useEffect(() => {
    localStorage.setItem("mode", mode === "dark" ? "dark" : "light");
  }, []);

  useEffect(() => {
    if (mode === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [mode, theme]);

  const { missions } = useMissions();
  const { dailyMissions } = useDailyMissions();

  const claimedDaily = () => {
    if (!dailyMissions || !dailyMissions?.length) {
      return false;
    }

    return checkSameDayV2(dailyMissions?.[0]?.timestamp);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hasMissions = useMemo(() => {
    if (!missions?.data?.length || !dailyMissions?.length) {
      return false;
    }

    const missionList = (missions?.data ?? [])?.filter((item: any) => {
      const isPremium = !!tele?.initDataUnsafe?.user?.is_premium;
      if (isPremium) {
        return item?.type !== missionsTypes.SIGN_IN && item?.tickets === 0;
      }
      return (
        item?.type !== missionsTypes.SIGN_IN &&
        item?.tickets === 0 &&
        !item?.metadata?.premium_only
      );
    });

    const hasMissions =
      missionList.some(
        (mission) => mission.task_user === null || !mission.task_user.completed
      ) || !claimedDaily();

    const hasBirdMission = missionList
      .filter((item: any) => !item.metadata?.excluded)
      .some(
        (mission) => mission.task_user === null || !mission.task_user.completed
      );

    setHasBirdMission(hasBirdMission);
    setHasMissions(hasMissions);
    return hasMissions;
  }, [missions?.data, dailyMissions]);

  const showNavBar =
    location.pathname === navPaths.HOME ||
    location.pathname === navPaths.MISSIONS ||
    location.pathname === navPaths.EARN ||
    location.pathname === navPaths.BOOTS ||
    location.pathname === navPaths.FRIENDS ||
    location.pathname === navPaths.AIR_DROP;

  const hasHalloweenTheme = useMemo(() => {
    return currentTheme === themeValue.halloween;
  }, [currentTheme]);

  return (
    <div
      className={clsx(
        "h-screen bg-gradient-to-b from-[#F7FFEB] via-[#E4FFBE] to-[#79B22A] fixed z-0 left-0 right-0 bottom-0 top-0",
        "dark:bg-none dark:bg-[#030C02]",
        "max-w-md mx-auto"
      )}
    >
      <div>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
        {!hasHalloweenTheme && <Stars />}
        {!hasHalloweenTheme && (
          <div className="hidden dark:block fixed h-[86px] bottom-0 left-0 z-0">
            <img
              src="/images/christmas/mountain.png?v=3"
              className="object-fill w-full"
              alt=""
            ></img>
          </div>
        )}
      </div>
      {showNavBar && (
        <div className={clsx("fixed left-0 right-0 bottom-0 z-50")}>
          <div
            className={clsx(
              "max-w-md mx-auto px-4 h-[86px] flex w-full navBar",
              !isMobile ? "items-center" : "pt-3",
              hasHalloweenTheme && location.pathname === navPaths.HOME
                ? "bg-gradient-to-b from-[#00000000] to-[#000000] border-t-[1px] border-t-[#FFFFFF1A]"
                : "bg-gradient-to-t from-[#fff] to-[#fff] dark:bg-gradient-to-t dark:from-[#0000004D] dark:to-[#000000] dark:backdrop-blur-lg dark:border dark:border-[#2E2E2E]"
            )}
          >
            <NavBar
              hasMission={hasMissions}
              halloweenTheme={hasHalloweenTheme}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeLayout;
