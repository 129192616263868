import { createBrowserRouter } from "react-router-dom";
import { missionsRoute } from "../modules/Missions/route";
import HomeLayout from "../components/layouts/HomeLayout";
import { bootsRoute } from "../modules/Boots/route";
import { homeRoute } from "../modules/Home/route";
import { friendsRoute } from "../modules/Friends/route";
import AuthLayout from "../components/layouts/AuthLayout";
import {
  BannedRoute,
  InactiveRoute,
  registerRoute,
} from "../modules/Register/route";
// import { leaderboardRoute } from "../modules/LeaderBoard/route";
import { anouncementRoute } from "../modules/Global/route";
import { tutRoute, votingRoute } from "../modules/Voting/route";
import { dappRoute } from "../modules/Dapp/route";
import { mysteryBoxRoute } from "../modules/MysteryBox/route";
import {
  inventoryRoute,
  inventoryDetailRoute,
} from "../modules/Inventory/route";
import { marketItemRoute, marketRoute } from "../modules/Market/route";
import { purchaseRoute } from "../modules/Purchase/route";
import { profileRoute } from "../modules/Profile/route";
import { spinEggRoute } from "../modules/SpinEgg/routes";
import { streakRoute } from "../modules/Streak/routes";
import { guildRoute } from "../modules/Guild/route";
import { birdCageRoute } from "../modules/BirdCage/route";
import { medalRoute } from "../modules/Medal/route";
import { gamesRoute } from "../modules/Games/route";
import { airdropRoute } from "../modules/Airdrop/route";
import { battleBirdRoute } from "../modules/BattleBird/route";

export const router = createBrowserRouter([
  {
    path: "",
    Component: AuthLayout,
    children: [
      registerRoute,
      {
        path: "",
        Component: HomeLayout,
        children: [
          homeRoute,
          missionsRoute,
          bootsRoute,
          friendsRoute,
          // leaderboardRoute,
          anouncementRoute,
          dappRoute,
          votingRoute,
          mysteryBoxRoute,
          tutRoute,
          inventoryRoute,
          marketRoute,
          marketItemRoute,
          inventoryDetailRoute,
          purchaseRoute,
          profileRoute,
          spinEggRoute,
          streakRoute,
          guildRoute,
          birdCageRoute,
          medalRoute,
          gamesRoute,
          airdropRoute,
          battleBirdRoute,
          InactiveRoute,
          BannedRoute,
        ],
      },
    ],
  },
  {
    path: "*",
    Component: undefined,
  },
]);
