// import { useCheckLoadImg } from "../../../store/imgStore";

// const LoadingScreen = () => {
//   const setLoadedImg = useCheckLoadImg((state: any) => state.setIsLoaded);

//   return (
//     <div
//       style={{ backgroundImage: "url('/images/loading.png')" }}
//       className="bg-cover bg-no-repeat w-full h-screen relative"
//     >
//       <img
//         src="/images/loading.png"
//         className="opacity-0"
//         alt=""
//         onLoad={() => setLoadedImg(true)}
//       ></img>
//       <div className="loader"></div>
//     </div>
//   );
// };

// export default LoadingScreen;

import { useCheckLoadImg } from "../../../store/imgStore";

const LoadingScreen = () => {
  const setLoadedImg = useCheckLoadImg((state: any) => state.setIsLoaded);

  // useEffect(() => {
  //   Array.from({ length: 7 }).map((_, index) => {
  //     const loaderId = document.getElementById(`loader${index}`);
  //     if (loaderId) {
  //       loaderId.style.backgroundColor = "#68D12D";
  //       if (index === 0) loaderId.style.height = "8px";
  //       if (index === 1) loaderId.style.height = "16px";
  //       if (index === 2) loaderId.style.height = "12px";
  //       if (index === 3) loaderId.style.height = "27px";
  //       if (index === 4) loaderId.style.height = "21px";
  //       if (index === 5) loaderId.style.height = "7px";
  //       if (index === 6) loaderId.style.height = "14px";
  //     }
  //     return null;
  //   });
  // }, []);

  return (
    <div className="bg-cover bg-no-repeat w-full h-screen relative bg-white flex flex-col items-center justify-center z-[99]">
      <img
        src={"/images/trees/main.png"}
        onLoad={() => setLoadedImg(true)}
        className="w-full h-[30%] object-contain -mt-[10%] mr-[2%]"
        alt="welcome-banner"
      ></img>
      <div className="loader"></div>
      {/* <div className="flex items-end gap-[10px] h-[27px]">
        {Array.from({ length: 7 }).map((_, index) => (
          <div
            key={index}
            id={`loader${index}`}
            className="rounded-[5px] w-[6px] h-[27px] loader"
          ></div>
        ))}
      </div> */}
      <div className="bg-[#61A70029] mt-5 rounded-[30px] h-[36px] text-[#4E8600] w-fit flex items-center justify-center px-6">
        <span className="text-[16px] font-[400]">
          Listing: <span className="font-[600]">December</span>
        </span>
      </div>
    </div>
  );
};

export default LoadingScreen;
