import clsx from "clsx";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { navPaths, navbarItems } from "../../constants/navbar.constants";
import { useLocation } from "react-router-dom";

const NavBar = ({
  hasMission,
  halloweenTheme,
}: {
  hasMission: boolean;
  halloweenTheme: boolean;
}) => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();
  const location = useLocation();
  const mode = localStorage.getItem("mode");

  const handleShowToast = (index: number, item: any) => {
    tele.HapticFeedback.impactOccurred("heavy");
    if (item.path === navPaths.DAPP || item.path === navPaths.VOTING) {
      !toast.isActive("coming_soon") &&
        toast.error("Coming soon", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
          toastId: "coming_soon",
        });
      return;
    }
    navigate(item.path);
    return;
  };

  return (
    <div className="w-full">
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <div>
        <div className={clsx("grid grid-cols-5 gap-2 ")}>
          {navbarItems.map((item, index) => (
            <div className="flex-1 relative" key={index}>
              {index === 1 && hasMission && (
                <div className="size-[8px] absolute z-10 right-5 top-1">
                  <div
                    className={clsx(
                      " w-full h-full absolute  rounded-[50%] ",
                      mode === "dark" ? "animate-blink-dark " : "animate-blink"
                    )}
                  ></div>
                  <div
                    className={clsx(
                      "w-full h-full absolute rounded-[50%]",
                      halloweenTheme
                        ? "bg-[#FA6F14]"
                        : mode === "dark"
                        ? "bg-[#64C347]"
                        : "bg-[#FFA928]"
                    )}
                  ></div>
                </div>
              )}
              {/* {index === 3 && (
                <div className="absolute -right-3 -top-2 z-10 pointer-events-none">
                  <img
                    src="/images/inventory/new.png?v=4"
                    className="w-[56px] h-[43]"
                    alt=""
                  ></img>
                </div>
              )} */}
              <div
                className={clsx("cursor-pointer w-full h-full p-[9px]")}
                onClick={() => handleShowToast(index, item)}
              >
                <div className="flex flex-col items-center gap-[2px]">
                  <img
                    className={clsx(
                      " object-contain",
                      index === 4 ? "h-[22px]" : "h-[20px]"
                    )}
                    width={index === 2 ? 43 : 36}
                    src={
                      location.pathname === item.path
                        ? halloweenTheme
                          ? item.halloween_icon
                          : mode === "dark"
                          ? item.darkIcon
                          : item.icon
                        : halloweenTheme && location.pathname === navPaths.HOME
                        ? item.halloween_iconInactive
                        : mode === "dark"
                        ? item.halloween_iconInactive
                        : item.iconInactive
                    }
                    alt={item.name}
                  ></img>
                  <p
                    className={clsx(
                      "text-xs font-medium",
                      location.pathname === item.path
                        ? halloweenTheme
                          ? "text-[#FA6F14]"
                          : mode === "dark"
                          ? "text-[#64C347]"
                          : "text-black"
                        : halloweenTheme && location.pathname === navPaths.HOME
                        ? "text-[#FFFFFF]"
                        : mode === "dark"
                        ? "text-white"
                        : "text-[#7D7D7D]"
                    )}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(NavBar);
