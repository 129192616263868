/* eslint-disable react-hooks/exhaustive-deps */
import { memo } from "react";

// const Star = ({ number }: any) => {
//   const starRef = useRef<any>();
//   useEffect(() => {
//     const staticPoistion = Math.floor(Math.random() * 2);
//     const staticValue = Math.floor(Math.random() * 2);
//     const flexValue = Math.floor(Math.random() * 101);
//     const delay = Math.random() * 5;

//     let dynamicStyles: any = null;

//     const addAnimation = (body: string) => {
//       if (!dynamicStyles) {
//         dynamicStyles = document.createElement("style");
//         dynamicStyles.type = "text/css";
//         document.head.appendChild(dynamicStyles);
//         dynamicStyles.sheet.insertRule(body, dynamicStyles.length);
//       }
//     };

//     addAnimation(`
//     @keyframes stars${number} {
//         from {
//           top: 0;
//            left: ${
//              staticPoistion === 1
//                ? staticValue === 0
//                  ? "0%"
//                  : "100%"
//                : `${flexValue}%`
//            };
//           transform:scale(1.5);
//           opacity: 0
//         }
//         to {
//           top: "100%"
//           left: ${`${flexValue}%`};
//           opacity: 1;
//         }
//       }
//     `);

//     starRef.current.style.animation = `stars${number} 7s linear infinite `;
//     starRef.current.style.animationDelay = `${delay}s`;
//   }, [starRef]);
//   return <div className="snow">&#10052;</div>;
// };

const Stars = () => {
  return (
    <div className="initial-snow">
      {[...Array(40)].map((item, index) => {
        return (
          <div className="snow">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12290_13776)">
                <path
                  d="M8.59765 7.72221C8.77423 7.75209 8.94265 7.62986 8.96982 7.45332L10.1135 0.377904C10.1434 0.201358 10.0211 0.0329607 9.84454 0.00579983C9.66796 -0.0213611 9.49954 0.0981469 9.47237 0.274693L8.32872 7.34739C8.29884 7.52393 8.42108 7.69233 8.59765 7.71949V7.72221Z"
                  fill="white"
                />
                <path
                  d="M9.43407 2.54C9.51285 2.55358 9.59435 2.53728 9.66498 2.49111L11.4796 1.29875C11.629 1.20097 11.6725 0.997262 11.572 0.847877C11.4742 0.698492 11.2704 0.655035 11.121 0.75553L9.3064 1.94789C9.15699 2.04567 9.11352 2.24938 9.21404 2.39876C9.26565 2.47753 9.34715 2.52642 9.43407 2.54272V2.54Z"
                  fill="white"
                />
                <path
                  d="M9.4342 2.5393C9.52113 2.55288 9.61349 2.53115 9.68956 2.47411C9.83082 2.36275 9.85526 2.15905 9.74389 2.01781L8.39649 0.317538C8.28511 0.176301 8.08138 0.151856 7.94012 0.263216C7.79886 0.374575 7.77441 0.578282 7.88579 0.719519L9.23318 2.41979C9.2848 2.48498 9.35814 2.52572 9.43692 2.5393H9.4342Z"
                  fill="white"
                />
                <path
                  d="M9.18993 4.0693C9.26871 4.08288 9.35021 4.06658 9.42084 4.02041L11.2355 2.82804C11.3849 2.73027 11.4283 2.52656 11.3278 2.37717C11.23 2.22779 11.0263 2.18433 10.8769 2.28483L9.06226 3.47719C8.91285 3.57497 8.86938 3.77868 8.96989 3.92806C9.02151 4.00683 9.103 4.05572 9.18993 4.0693Z"
                  fill="white"
                />
                <path
                  d="M9.18985 4.06936C9.27678 4.08294 9.36914 4.06121 9.44249 4.00417C9.58375 3.89281 9.60819 3.68911 9.49682 3.54787L8.14942 1.84488C8.03804 1.70364 7.8343 1.6792 7.69305 1.79056C7.55179 1.90192 7.52734 2.10563 7.63872 2.24686L8.98611 3.94985C9.03772 4.01775 9.11107 4.05578 9.18985 4.06936Z"
                  fill="white"
                />
                <path
                  d="M8.93993 5.61422C9.01871 5.6278 9.10021 5.6115 9.17084 5.56533L10.9855 4.37297C11.1349 4.27519 11.1783 4.07148 11.0778 3.9221C10.98 3.77271 10.7763 3.72925 10.6269 3.82975L8.81226 5.02211C8.66285 5.11989 8.61938 5.3236 8.71989 5.47298C8.77151 5.55175 8.853 5.60064 8.93993 5.61422Z"
                  fill="white"
                />
                <path
                  d="M8.93985 5.61428C9.02678 5.62786 9.11914 5.60613 9.19249 5.54909C9.33375 5.43773 9.35819 5.23403 9.24682 5.09279L7.89942 3.3898C7.78804 3.24857 7.5843 3.22412 7.44305 3.33548C7.30179 3.44684 7.27734 3.65055 7.38872 3.79178L8.73611 5.49477C8.78772 5.56267 8.86107 5.6007 8.93985 5.61428Z"
                  fill="white"
                />
                <path
                  d="M7.7777 7.93514C7.85919 7.94873 7.94612 7.92971 8.01947 7.87811C8.16616 7.77218 8.19876 7.56847 8.09281 7.42452L3.90122 1.61209C3.79528 1.46542 3.59154 1.43283 3.44756 1.53876C3.30087 1.64468 3.26827 1.84839 3.37421 1.99234L7.56581 7.80477C7.61742 7.87811 7.6962 7.92156 7.7777 7.93514Z"
                  fill="white"
                />
                <path
                  d="M4.70515 3.67971C4.87629 3.70687 5.03928 3.59551 5.0746 3.42439L5.51467 1.30041C5.54999 1.12387 5.43861 0.952753 5.26204 0.914728C5.08003 0.879419 4.91432 0.990779 4.87629 1.16732L4.43621 3.29131C4.4009 3.46785 4.51228 3.63897 4.68885 3.67699C4.69428 3.67699 4.69972 3.67699 4.70243 3.67971H4.70515Z"
                  fill="white"
                />
                <path
                  d="M2.55101 3.42618C2.55101 3.42618 2.56188 3.42618 2.56459 3.42618L4.7188 3.67878C4.89809 3.70051 5.05836 3.57285 5.08009 3.39359C5.10183 3.21433 4.97415 3.05136 4.79486 3.03235L2.64066 2.77975C2.46137 2.75802 2.30109 2.88568 2.27936 3.06494C2.25763 3.23877 2.37987 3.39631 2.55101 3.42347V3.42618Z"
                  fill="white"
                />
                <path
                  d="M5.61238 4.9348C5.78352 4.96196 5.94651 4.8506 5.98182 4.67949L6.4219 2.55551C6.45721 2.37896 6.34584 2.20785 6.16926 2.16982C5.98454 2.13723 5.82155 2.24587 5.78352 2.42242L5.34344 4.5464C5.30813 4.72294 5.4195 4.89406 5.59608 4.93208C5.60151 4.93208 5.60694 4.93208 5.60966 4.9348H5.61238Z"
                  fill="white"
                />
                <path
                  d="M3.4558 4.68399C3.4558 4.68399 3.46395 4.68399 3.46938 4.68399L5.62358 4.93659C5.80287 4.95832 5.96315 4.83066 5.98488 4.6514C6.00661 4.47214 5.8735 4.31461 5.69964 4.29016L3.54544 4.03757C3.36615 4.01584 3.20588 4.14349 3.18414 4.32276C3.16241 4.49658 3.28465 4.65412 3.4558 4.68128V4.68399Z"
                  fill="white"
                />
                <path
                  d="M6.5279 6.2051C6.69904 6.23226 6.86204 6.1209 6.89735 5.94978L7.33743 3.8258C7.37274 3.64926 7.26136 3.47814 7.08479 3.44012C6.89735 3.40481 6.73708 3.51617 6.69904 3.69272L6.25897 5.8167C6.22365 5.99324 6.33503 6.16436 6.5116 6.20238C6.51704 6.20238 6.52247 6.20238 6.52519 6.2051H6.5279Z"
                  fill="white"
                />
                <path
                  d="M4.37132 5.95353C4.37132 5.95353 4.37947 5.95353 4.38491 5.95353L6.53911 6.20612C6.7184 6.22785 6.87867 6.10019 6.90041 5.92093C6.92214 5.74167 6.78903 5.58142 6.61517 5.55969L4.46097 5.3071C4.28168 5.28537 4.1214 5.41302 4.09967 5.59229C4.07794 5.76612 4.20018 5.92365 4.37132 5.95081V5.95353Z"
                  fill="white"
                />
                <path
                  d="M7.34807 8.67212C7.52465 8.70199 7.69307 8.57977 7.72024 8.40322C7.75012 8.22668 7.62788 8.05828 7.4513 8.0284L0.377476 6.8795C0.200902 6.84962 0.0324774 6.97185 0.00531216 7.14839C-0.0218531 7.32494 0.097674 7.49334 0.274248 7.52321L7.34807 8.67212Z"
                  fill="white"
                />
                <path
                  d="M2.16786 7.82995C2.24663 7.84353 2.32813 7.82724 2.39876 7.78106C2.54817 7.68329 2.59163 7.47958 2.49112 7.33019L1.30128 5.51585C1.20349 5.36646 0.999751 5.323 0.850342 5.4235C0.700933 5.52128 0.657469 5.72498 0.75798 5.87437L1.94782 7.68872C1.99943 7.76748 2.08093 7.81637 2.16786 7.83267V7.82995Z"
                  fill="white"
                />
                <path
                  d="M0.464525 9.17372C0.551454 9.18731 0.643815 9.16558 0.717161 9.10854L2.42042 7.76408C2.56168 7.65272 2.58613 7.44901 2.47475 7.30777C2.36337 7.16654 2.15692 7.14209 2.01838 7.25345L0.315116 8.59791C0.173857 8.70927 0.149408 8.91298 0.260786 9.05422C0.312399 9.1194 0.385746 9.16014 0.464525 9.17372Z"
                  fill="white"
                />
                <path
                  d="M3.69422 8.078C3.773 8.09158 3.8545 8.07528 3.92513 8.02911C4.07454 7.93133 4.118 7.72763 4.01749 7.57824L2.82765 5.76389C2.72986 5.61451 2.52612 5.57105 2.37671 5.67155C2.2273 5.76933 2.18384 5.97303 2.28435 6.12242L3.47418 7.93676C3.5258 8.01553 3.60729 8.06442 3.69422 8.08072V8.078Z"
                  fill="white"
                />
                <path
                  d="M1.99089 9.42373C2.07782 9.43731 2.17018 9.41558 2.24353 9.35854L3.94679 8.01408C4.08805 7.90272 4.1125 7.69901 4.00112 7.55777C3.88974 7.41654 3.686 7.39209 3.54474 7.50345L1.84148 8.84791C1.70022 8.95927 1.67578 9.16298 1.78715 9.30422C1.83877 9.3694 1.91211 9.41014 1.99089 9.42373Z"
                  fill="white"
                />
                <path
                  d="M5.24012 8.32995C5.3189 8.34353 5.4004 8.32724 5.47103 8.28106C5.62043 8.18329 5.6639 7.97958 5.56339 7.83019L4.37355 6.01585C4.27576 5.86646 4.07202 5.823 3.92261 5.9235C3.7732 6.02128 3.72973 6.22498 3.83025 6.37437L5.02008 8.18872C5.0717 8.26748 5.15319 8.31637 5.24012 8.33267V8.32995Z"
                  fill="white"
                />
                <path
                  d="M3.53679 9.67372C3.62372 9.68731 3.71608 9.66558 3.78943 9.60854L5.49269 8.26408C5.63395 8.15272 5.65839 7.94901 5.54702 7.80777C5.43564 7.66654 5.2319 7.64209 5.09064 7.75345L3.38738 9.09791C3.24612 9.20927 3.22167 9.41298 3.33305 9.55422C3.38467 9.6194 3.45801 9.66014 3.53679 9.67372Z"
                  fill="white"
                />
                <path
                  d="M1.74675 13.6813C1.82824 13.6948 1.91517 13.6758 1.98852 13.6242L7.80459 9.43872C7.95129 9.3328 7.98388 9.12909 7.87794 8.98514C7.772 8.83847 7.56826 8.80588 7.42428 8.9118L1.60821 13.0973C1.46151 13.2032 1.42891 13.4069 1.53486 13.5509C1.58647 13.6242 1.66525 13.6677 1.74675 13.6813Z"
                  fill="white"
                />
                <path
                  d="M3.30341 12.5621C3.47455 12.5893 3.63754 12.4779 3.67285 12.3068C3.70817 12.1303 3.59679 11.9592 3.42022 11.9211L1.29589 11.4784C1.12204 11.4458 0.94818 11.5545 0.910148 11.731C0.872117 11.9076 0.986211 12.0787 1.16279 12.1167L3.28711 12.5594C3.28711 12.5594 3.29797 12.5594 3.30069 12.5621H3.30341Z"
                  fill="white"
                />
                <path
                  d="M3.05101 14.7168C3.05101 14.7168 3.05916 14.7168 3.06459 14.7168C3.24388 14.7385 3.40416 14.6108 3.42589 14.4316L3.68124 12.2777C3.70298 12.0985 3.58073 11.9382 3.39601 11.9165C3.21672 11.8947 3.05644 12.0224 3.03471 12.2017L2.77936 14.3555C2.75763 14.5293 2.87987 14.6869 3.05101 14.714V14.7168Z"
                  fill="white"
                />
                <path
                  d="M4.56092 11.6583C4.73206 11.6854 4.89505 11.5741 4.93036 11.4029C4.96568 11.2264 4.8543 11.0553 4.67773 11.0173L2.5534 10.5745C2.38226 10.5338 2.20569 10.6506 2.16766 10.8271C2.13234 11.0037 2.24372 11.1748 2.4203 11.2128L4.54462 11.6555C4.54462 11.6555 4.55548 11.6555 4.5582 11.6583H4.56092Z"
                  fill="white"
                />
                <path
                  d="M4.30589 13.8125C4.30589 13.8125 4.31404 13.8125 4.31948 13.8125C4.49877 13.8342 4.65904 13.7065 4.68077 13.5273L4.93613 11.3734C4.95786 11.1942 4.83833 11.0339 4.65089 11.0122C4.4716 10.9904 4.31133 11.1181 4.28959 11.2974L4.03424 13.4512C4.01251 13.6251 4.13475 13.7826 4.30589 13.8097V13.8125Z"
                  fill="white"
                />
                <path
                  d="M5.82977 10.7422C6.00091 10.7694 6.1639 10.658 6.19922 10.4869C6.23725 10.3104 6.12316 10.1393 5.94658 10.1012L3.82226 9.65852C3.65384 9.61778 3.47455 9.73457 3.43652 9.91111C3.39848 10.0877 3.51258 10.2588 3.68915 10.2968L5.81347 10.7395C5.81347 10.7395 5.82434 10.7395 5.82706 10.7422H5.82977Z"
                  fill="white"
                />
                <path
                  d="M5.57738 12.8964C5.57738 12.8964 5.58553 12.8964 5.59096 12.8964C5.77025 12.9182 5.93053 12.7905 5.95226 12.6113L6.20761 10.4574C6.22934 10.2781 6.10982 10.1152 5.92238 10.0962C5.74309 10.0744 5.58281 10.2021 5.56108 10.3813L5.30573 12.5352C5.28399 12.709 5.40624 12.8666 5.57738 12.8937V12.8964Z"
                  fill="white"
                />
                <path
                  d="M7.14453 16.9943C7.3211 17.0242 7.48953 16.902 7.51669 16.7255L8.67122 9.65276C8.7011 9.47621 8.587 9.31053 8.40228 9.27794C8.22571 9.24806 8.05728 9.37028 8.03012 9.54683L6.87559 16.6195C6.84571 16.7961 6.96796 16.9645 7.14453 16.9943Z"
                  fill="white"
                />
                <path
                  d="M5.63664 16.2918C5.71542 16.3054 5.79692 16.2891 5.86755 16.2429L7.68218 15.056C7.83159 14.9582 7.87506 14.7572 7.77726 14.6051C7.67947 14.453 7.47573 14.4123 7.32632 14.5101L5.51168 15.697C5.36227 15.7948 5.31881 15.9958 5.4166 16.1479C5.46822 16.2266 5.54971 16.2782 5.63664 16.2918Z"
                  fill="white"
                />
                <path
                  d="M8.79602 16.8076C8.88295 16.8212 8.97531 16.7995 9.04866 16.7425C9.18992 16.6311 9.21436 16.4274 9.10299 16.2861L7.75831 14.5832C7.64693 14.4419 7.44319 14.4175 7.30193 14.5288C7.16067 14.6402 7.13622 14.8439 7.2476 14.9851L8.59228 16.6881C8.64389 16.756 8.71724 16.7941 8.79602 16.8076Z"
                  fill="white"
                />
                <path
                  d="M5.88664 14.7645C5.96542 14.778 6.04692 14.7618 6.11755 14.7156L7.93218 13.5286C8.08159 13.4309 8.12506 13.2299 8.02726 13.0778C7.92947 12.9257 7.72573 12.8849 7.57632 12.9827L5.76168 14.1696C5.61227 14.2674 5.56881 14.4684 5.6666 14.6205C5.71822 14.6993 5.79971 14.7509 5.88664 14.7645Z"
                  fill="white"
                />
                <path
                  d="M9.04602 15.2783C9.13295 15.2919 9.22531 15.2702 9.29866 15.2132C9.43992 15.1018 9.46436 14.8981 9.35299 14.7569L8.00831 13.0539C7.89693 12.9126 7.69319 12.8882 7.55193 12.9995C7.41067 13.1109 7.38622 13.3146 7.4976 13.4558L8.84228 15.1588C8.89389 15.2267 8.96724 15.2648 9.04602 15.2783Z"
                  fill="white"
                />
                <path
                  d="M6.13908 13.2195C6.21786 13.2331 6.29936 13.2168 6.36999 13.1707L8.18463 11.9837C8.33403 11.8859 8.3775 11.685 8.2797 11.5329C8.18191 11.3808 7.97817 11.34 7.82876 11.4378L6.01412 12.6247C5.86472 12.7225 5.82125 12.9235 5.91905 13.0756C5.97066 13.1544 6.05216 13.206 6.13908 13.2195Z"
                  fill="white"
                />
                <path
                  d="M9.29602 13.7354C9.38295 13.749 9.47531 13.7272 9.54866 13.6702C9.68992 13.5588 9.71436 13.3551 9.60299 13.2139L8.25831 11.5109C8.14693 11.3697 7.94319 11.3452 7.80193 11.4566C7.66067 11.5679 7.63622 11.7716 7.7476 11.9129L9.09228 13.6159C9.1439 13.6838 9.21724 13.7218 9.29602 13.7354Z"
                  fill="white"
                />
                <path
                  d="M13.3028 15.5285C13.3842 15.542 13.4712 15.523 13.5445 15.4714C13.6912 15.3655 13.7238 15.1618 13.6179 15.0178L9.43442 9.19998C9.33119 9.05331 9.12746 9.02072 8.98076 9.12665C8.83407 9.23257 8.80147 9.43628 8.90742 9.58023L13.0909 15.3981C13.1425 15.4714 13.2213 15.5149 13.3028 15.5285Z"
                  fill="white"
                />
                <path
                  d="M11.7406 16.0934C11.9117 16.1206 12.0747 16.0092 12.1101 15.8381L12.5556 13.7141C12.5936 13.5376 12.4795 13.3665 12.3029 13.3284C12.1236 13.2877 11.9552 13.4045 11.9172 13.581L11.4717 15.705C11.4336 15.8816 11.5477 16.0527 11.7243 16.0907C11.7297 16.0907 11.7352 16.0907 11.7406 16.0934Z"
                  fill="white"
                />
                <path
                  d="M12.1833 13.9692C12.1833 13.9692 12.1915 13.9692 12.1969 13.9692L14.3511 14.2245C14.5304 14.2462 14.6907 14.1185 14.7124 13.9393C14.7341 13.76 14.6119 13.597 14.4272 13.578L12.273 13.3227C12.0937 13.301 11.9334 13.4286 11.9117 13.6079C11.89 13.7817 12.0122 13.9393 12.1833 13.9664V13.9692Z"
                  fill="white"
                />
                <path
                  d="M10.8363 14.8367C11.0075 14.8639 11.1704 14.7525 11.2058 14.5814L11.6513 12.4575C11.6893 12.2809 11.5752 12.1098 11.3986 12.0718C11.2221 12.0337 11.0509 12.1478 11.0129 12.3244L10.5674 14.4483C10.5293 14.6249 10.6434 14.796 10.82 14.834C10.8254 14.834 10.8309 14.834 10.8363 14.8367Z"
                  fill="white"
                />
                <path
                  d="M11.279 12.7152C11.279 12.7152 11.2872 12.7152 11.2926 12.7152L13.4468 12.9706C13.6261 12.9923 13.7864 12.8646 13.8081 12.6854C13.8299 12.5061 13.7103 12.3404 13.5229 12.3241L11.3687 12.0688C11.1894 12.0471 11.0291 12.1747 11.0074 12.354C10.9857 12.5278 11.1079 12.6854 11.279 12.7125V12.7152Z"
                  fill="white"
                />
                <path
                  d="M9.92322 13.5672C10.0944 13.5944 10.2574 13.483 10.2927 13.3119L10.7382 11.1879C10.7762 11.0114 10.6621 10.8403 10.4855 10.8022C10.3117 10.7642 10.1378 10.8783 10.0998 11.0548L9.65429 13.1788C9.61626 13.3554 9.73035 13.5265 9.90693 13.5645C9.91236 13.5645 9.91779 13.5645 9.92051 13.5672H9.92322Z"
                  fill="white"
                />
                <path
                  d="M10.366 11.4438C10.366 11.4438 10.3741 11.4438 10.3795 11.4438L12.5337 11.6991C12.713 11.7208 12.8733 11.5931 12.895 11.4139C12.9168 11.2346 12.8 11.0717 12.6098 11.0526L10.4556 10.7973C10.2763 10.7756 10.116 10.9033 10.0943 11.0825C10.0726 11.2564 10.1948 11.4139 10.366 11.441V11.4438Z"
                  fill="white"
                />
                <path
                  d="M16.6224 10.1342C16.799 10.164 16.9674 10.0418 16.9945 9.86526C17.0244 9.68871 16.9022 9.52032 16.7256 9.49044L9.6545 8.33067C9.47792 8.30079 9.3095 8.42302 9.28233 8.59956C9.25245 8.77611 9.37469 8.94451 9.55127 8.97438L16.6224 10.1342Z"
                  fill="white"
                />
                <path
                  d="M15.916 11.6426C15.9948 11.6562 16.0763 11.6426 16.147 11.5938C16.2964 11.496 16.3398 11.295 16.242 11.1429L15.0549 9.32582C14.9571 9.17372 14.7534 9.13298 14.604 9.23076C14.4546 9.32854 14.4111 9.52953 14.5089 9.68163L15.696 11.4987C15.7476 11.5775 15.8291 11.6291 15.916 11.6426Z"
                  fill="white"
                />
                <path
                  d="M14.7292 9.82531C14.8161 9.83889 14.9085 9.81716 14.9818 9.76012L16.6878 8.41837C16.829 8.30701 16.8535 8.10331 16.7421 7.96207C16.6307 7.82083 16.427 7.79639 16.2857 7.90775L14.5798 9.24949C14.4385 9.36085 14.4141 9.56456 14.5254 9.7058C14.577 9.7737 14.6504 9.81444 14.7292 9.82531Z"
                  fill="white"
                />
                <path
                  d="M14.3892 11.3926C14.468 11.4062 14.5495 11.3926 14.6201 11.3438C14.7695 11.246 14.813 11.045 14.7152 10.8929L13.5281 9.07582C13.4303 8.92372 13.2265 8.88298 13.0771 8.98076C12.9277 9.07854 12.8842 9.27953 12.982 9.43163L14.1692 11.2487C14.2208 11.3275 14.3023 11.3791 14.3892 11.3926Z"
                  fill="white"
                />
                <path
                  d="M13.2023 9.57531C13.2892 9.58889 13.3816 9.56716 13.455 9.51012L15.1609 8.16837C15.3022 8.05701 15.3266 7.85331 15.2153 7.71207C15.1039 7.57083 14.9001 7.54639 14.7589 7.65775L13.0529 8.9995C12.9116 9.11085 12.8872 9.31456 12.9986 9.4558C13.0502 9.5237 13.1235 9.56444 13.2023 9.57531Z"
                  fill="white"
                />
                <path
                  d="M12.8438 11.1368C12.9226 11.1504 13.0041 11.1368 13.0747 11.0879C13.2241 10.9901 13.2676 10.7891 13.1698 10.637L11.9826 8.81996C11.8849 8.66786 11.6838 8.62712 11.5317 8.7249C11.3823 8.82268 11.3388 9.02367 11.4366 9.17577L12.6237 10.9928C12.6754 11.0716 12.7569 11.1232 12.8438 11.1368Z"
                  fill="white"
                />
                <path
                  d="M11.6564 9.31945C11.7433 9.33303 11.8357 9.3113 11.9091 9.25426L13.615 7.91251C13.7563 7.80115 13.7807 7.59745 13.6694 7.45621C13.558 7.31497 13.3542 7.29053 13.213 7.40189L11.507 8.74364C11.3658 8.85499 11.3413 9.0587 11.4527 9.19994C11.5043 9.26784 11.5776 9.30858 11.6564 9.31945Z"
                  fill="white"
                />
                <path
                  d="M9.33692 8.15027C9.41842 8.16385 9.50534 8.14484 9.57869 8.09324L15.4002 3.91589C15.5469 3.80996 15.5795 3.60897 15.4763 3.4623C15.3703 3.31563 15.1666 3.28304 15.0226 3.38625L9.20109 7.5636C9.0544 7.66953 9.0218 7.87323 9.12503 8.01718C9.17665 8.09052 9.25542 8.13669 9.33692 8.14756V8.15027Z"
                  fill="white"
                />
                <path
                  d="M15.7233 5.53751C15.8945 5.56467 16.0575 5.45331 16.0928 5.2822C16.1308 5.10565 16.0167 4.93454 15.8401 4.89651L13.7158 4.45107C13.5392 4.41848 13.3681 4.52712 13.3301 4.70367C13.292 4.88021 13.4061 5.05133 13.5827 5.08935L15.707 5.53479C15.707 5.53479 15.7179 5.53479 15.7233 5.53751Z"
                  fill="white"
                />
                <path
                  d="M13.6018 5.09176C13.6018 5.09176 13.6099 5.09176 13.6154 5.09176C13.7947 5.11349 13.9549 4.98583 13.9767 4.80657L14.2347 2.65271C14.2565 2.47345 14.1234 2.32135 13.9495 2.29147C13.7702 2.26974 13.6099 2.3974 13.5882 2.57666L13.3301 4.73052C13.3084 4.90435 13.4307 5.06188 13.6018 5.09176Z"
                  fill="white"
                />
                <path
                  d="M14.4655 6.43866C14.6367 6.46582 14.7996 6.35447 14.835 6.18335C14.873 6.00681 14.7589 5.83569 14.5823 5.79767L12.458 5.35223C12.2841 5.31692 12.1103 5.42828 12.0723 5.60482C12.0342 5.78137 12.1483 5.95248 12.3249 5.99051L14.4492 6.43595C14.4492 6.43595 14.4601 6.43595 14.4655 6.43866Z"
                  fill="white"
                />
                <path
                  d="M12.344 5.9941C12.344 5.9941 12.3521 5.9941 12.3576 5.9941C12.5369 6.01583 12.6971 5.88818 12.7189 5.70891L12.9769 3.55506C12.9987 3.37579 12.8656 3.21011 12.6917 3.19382C12.5124 3.17209 12.3521 3.29974 12.3304 3.47901L12.0723 5.63286C12.0506 5.80669 12.1728 5.96423 12.344 5.9941Z"
                  fill="white"
                />
                <path
                  d="M13.1945 7.35077C13.3657 7.37793 13.5287 7.26657 13.564 7.09546C13.602 6.91891 13.4879 6.7478 13.3113 6.70978L11.187 6.26434C11.0132 6.22903 10.8393 6.34039 10.8013 6.51693C10.7632 6.69348 10.8773 6.86459 11.0539 6.90262L13.1782 7.34806C13.1782 7.34806 13.1891 7.34806 13.1945 7.35077Z"
                  fill="white"
                />
                <path
                  d="M11.0701 6.90621C11.0701 6.90621 11.0782 6.90621 11.0836 6.90621C11.2629 6.92794 11.4232 6.80029 11.4449 6.62102L11.703 4.46717C11.7247 4.2879 11.5916 4.12765 11.4178 4.10593C11.2385 4.0842 11.0782 4.21185 11.0565 4.39111L10.7984 6.54497C10.7767 6.7188 10.8989 6.87634 11.0701 6.90621Z"
                  fill="white"
                />
                <path
                  d="M7.51111 9.42282L7.14166 8.45046C7.10092 8.34182 7.11993 8.21959 7.19328 8.12996L7.85067 7.32329C7.92402 7.23365 8.04083 7.1902 8.15493 7.20649L9.18177 7.37217C9.29586 7.39119 9.39366 7.46995 9.43441 7.5786L9.80385 8.55096C9.8446 8.6596 9.82559 8.78182 9.75224 8.87146L9.09484 9.67813C9.0215 9.76777 8.90469 9.81122 8.79059 9.79493L7.76375 9.62925C7.64965 9.61023 7.55186 9.53147 7.51111 9.42282ZM9.12744 8.608L8.8911 7.98601L8.23642 7.88008L7.81808 8.39614L8.05441 9.01541L8.7091 9.12134L9.12744 8.60528V8.608Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_12290_13776">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );
      })}
    </div>
  );
};

export default memo(Stars);
